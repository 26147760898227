<template>
<div>
    <div class="form-row">
        <v-text-field v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="عنوان داشبورد" id="dashboard-name" v-model="dashboard.title"></v-text-field>
        <div class="col-12" v-if="!isMobile">
            <input type="text" id="dashboard-name" placeholder="عنوان داشبورد" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="dashboard.title">
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveChanges()" :class="'m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary rename-dashboard ' + (addEditLoading ? 'loading-active inline-flex' : '')"><i class="uil uil-save"></i> ویرایش</a>
        </div>
    </div>
</div>
</template>

<script>
// این کامپوننت مودال ویرایش داشبورد میباشد
export default {
    name: 'dashboardEditModal',
    props: ['data'],
    components: {},
    data: function () {
        return {
            dashboard: {
                id: null,
                title: ''
            },
            addEditLoading: false,
            isMobile:false,
        }
    },
    mounted() {
        this.dashboard = this.$helpers.unbindObject(this.data.dashboard);
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد درخواست ویرایش داشبورد را ارسال میکند
        saveChanges() {
            if (this.dashboard.title) {
                let api_data = {
                    title: this.dashboard.title
                };
                this.addEditLoading = true;

                this.$helpers.makeRequest('POST', '/dashboard/edit/' + this.dashboard.id, api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 200) {
                        this.$parent.emitData('dashboardEdit', { dashboard: this.dashboard }, true);
                        this.$swal.fire({
                            icon: "success",
                            title: "داشبورد با موفقیت ویرایش شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در ویرایش داشبورد رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                    this.addEditLoading = false;
                });
            }
        },
    },
}
</script>
